import http from '../../../../../../libs/http'
import calidad from '../../../../../../config/calidad-pharmasan'
// export default (param) => http.get(`${calidad.host}/carpetas`, param)
export default class CarpetasServices {
    putTipoProceso (param) {
      return http.put(`${calidad.host}/mapa-procesos`, param, {
      })
    }

    putProceso (param) {
      return http.put(`${calidad.host}/mapa-procesos`, param, {
      })
    }

    putCarpeta (param) {
      return http.put(`${calidad.host}/mapa-procesos`, param, {
      })
    }

    putDocumento (param) {
      return http.put(`${calidad.host}/mapa-procesos`, param, {
      })
    }

    putArchivo (param) {
      return http.put(`${calidad.host}/archivos`, param, {
      })
    }

    reemplazarArchivo (id, param) {
      return http.put(`${calidad.host}/archivos/reemplazar/${id}`, param, {
      })
    }

    ordenarTipo (param) {
      return http.put(`${calidad.host}/tipo-procesos/ordenar`, param, {
      })
    }

    ordenarProceso (param) {
      return http.put(`${calidad.host}/procesos/ordenar`, param, {
      })
    }

    ordenarCarpeta (param) {
      return http.put(`${calidad.host}/carpetas/ordenar`, param, {
      })
    }

    ordenar (param) {
      return http.put(`${calidad.host}/mapa-procesos/ordenar`, param, {
      })
    }
  }
