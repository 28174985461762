import http from '../../../../../../libs/http'
import calidad from '../../../../../../config/calidad-pharmasan'
// export default (param) => http.get(`${calidad.host}/carpetas`, param)
export default class CarpetasServices {
    crearTipoProceso (param) {
      return http.post(`${calidad.host}/mapa-procesos`, param, {
      })
    }

    crearProceso (param) {
      return http.post(`${calidad.host}/mapa-procesos`, param, {
      })
    }

    crearCarpeta (param) {
      return http.post(`${calidad.host}/mapa-procesos`, param, {
      })
    }

    crearDocumento (param) {
      return http.post(`${calidad.host}/mapa-procesos`, param, {
      })
    }

    crearArchivo (param) {
      return http.post(`${calidad.host}/archivos`, param, {
      })
    }

    remove (id) {
      return http.delete(`${calidad.host}/mapa-procesos/${id}`, {
      })
    }
  }
